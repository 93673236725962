<template>
 
    <div class="flgpdmodal-container" :style="{ 'background-color': corFundo, 'border-color': corFundo }">
                <div class="flex-grid">
                    <div class="d-col-4 m-col-12 facillgpd-display-ajuste">
                        <img
                            v-if="$store.state.empresa && $store.state.empresa.logo && $store.state.empresa.logo.filename"
                            width="100%"
                            :src="`${url_api}/upload?mimetype=image/jpeg&filename=${$store.state.empresa.logo.filename}&folder=empresas`"
                            alt="facilgpd logo empresa"
                        />
                        <img
                            v-else
                            width="100%"
                            src="https://facillgpd.com.br/imgs/logo.png"
                            alt="facilgpd logo empresa"
                        />
                    </div>
                    <div class="d-col-8 m-col-12">
                        <h2 class="facillgpd-title" :style="{ 'color': corSubtitulos }">SUA PRIVACIDADE</h2>
                        <button v-if="exibir === 'requisicao'" @click="exibir = 'requisicao'" class="facillgpd-button" id="facillgpd-requisicao" type="button" style="margin-right: 5px;" :style="{ 'background-color': corButton, 'color': corSubtitulos }">
                            Requisição de direitos
                        </button>
                        <button v-else @click="exibir = 'requisicao'" class="facillgpd-button" id="facillgpd-requisicao" type="button" style="margin-right: 5px;" :style="{ 'background-color': corSubtitulos, 'color': corButton }">
                            Requisição de direitos
                        </button>
                        <button v-if="exibir === 'denuncia'" @click="exibir = 'denuncia'" class="facillgpd-button" id="facillgpd-denuncia" type="button" :style="{ 'background-color': corButton, 'color': corSubtitulos }">
                            Canal de denúncias
                        </button>
                        <button v-else @click="exibir = 'denuncia'" class="facillgpd-button" id="facillgpd-denuncia" type="button" style="margin-right: 5px;" :style="{ 'background-color': corSubtitulos, 'color': corButton }">
                            Canal de denúncias
                        </button>
                    </div>
                </div>
                <div v-if="exibir === 'requisicao'" id="requisicao">
                    <p class="facillgpd-subtitle" :style="{ 'color': corSubtitulos }"><span id="textInicial">{{ getHtmlFrom(textInicial) }}</span></p>
                    <p class="facillgpd-subtitle" :style="{ 'color': corSubtitulos }" v-html="getHtmlFrom(textSequencia)"></p>

                    <div class="flex-grid">
                        <div class="d-col-7 m-col-12">
                            <label class="facillgpdlabel" for="nome" :style="{ 'color': corSubtitulos }">Primeiro e último nome</label>
                            <input class="facillgpdinput" type="text" name="nome" id="nome">
                        </div> 

                        <div class="d-col-5 m-col-12">
                            <label class="facillgpdlabel" for="cpf_cnpj" :style="{ 'color': corSubtitulos }">3 últimos digitos do CPF</label>
                            <input class="facillgpdinput" type="text" name="cpf_cnpj" id="cpf_cnpj">
                        </div> 
                    </div>
                    
                    <div class="flex-grid">
                        <div class="d-col-7 m-col-12">
                            <label class="facillgpdlabel" for="email" :style="{ 'color': corSubtitulos }">Email</label>
                            <input class="facillgpdinput" type="text" name="email" id="email">
                        </div> 

                        <div class="d-col-5 m-col-12">
                            <label class="facillgpdlabel" for="telefone" :style="{ 'color': corSubtitulos }">Telefone</label>
                            <input class="facillgpdinput" type="text" name="telefone" id="telefone">
                        </div> 
                    </div>

                    <div class="flex-grid">
                        <div class="d-col-5 m-col-12">
                            <label class="facillgpdlabel" for="tipoTitular" :style="{ 'color': corSubtitulos }">Seu perfil como titular dos dados</label>
                            <select class="facillgpdselect" required name="tipoTitular" id="tipoTitular">
                                ##TIPOSTITULARES##
                            </select>
                        </div> 

                        <div class="d-col-7 m-col-12">
                            <label class="facillgpdlabel" for="solicitacao" :style="{ 'color': corSubtitulos }">Solicitação</label>
                            <select class="facillgpdselect" required name="solicitacao" id="solicitacao">
                                ##SOLICITACOES##
                            </select>
                        </div> 
                    </div>

                    <div class="flex-grid">
                        <div class="d-col-12 m-col-12">
                            <label class="facillgpdlabel" for="descricao" :style="{ 'color': corSubtitulos }">Detalhes da solicitação</label>
                            <textarea class="facillgpdtextarea" type="text" name="descricao" id="descricao" rows="3"></textarea>
                        </div>
                    </div>

                    <div class="facillgpdfeedback" id="facillgpdfeedback">
                        <p id="facillgpdtxt-feedback"></p>
                    </div>
                    
                    <button class="facillgpd-button" id="facillgpd-button" type="button" :style="{ 'background-color': corButton, 'color': corSubtitulos }">
                        Enviar Solicitação
                    </button>
                </div>

                <div v-if="exibir === 'denuncia'" id="denuncia">
                    <p class="facillgpd-subtitle" v-html="getHtmlFrom(textDenuncia)"></p>
                    <div class="flex-grid">
                        <div class="d-col-5 m-col-12">
                            <label class="facillgpdlabel" for="tipoDenuncia">Seu perfil como titular dos dados</label>
                            <select class="facillgpdselect" required name="tipoDenuncia" id="tipoDenuncia">
                                <option value="Anônima">Anônima</option>
                                <option value="Identificada">Identificada</option>
                            </select>
                        </div> 
                        <div class="d-col-7 m-col-12">
                            <label class="facillgpdlabel" for="nome_denuncia">Primeiro nome (caso queira se identificar)</label>
                            <input class="facillgpdinput" type="text" name="nome_denuncia" id="nome_denuncia">
                        </div> 
                    </div>
                    <div class="flex-grid">
                        <div class="d-col-12 m-col-12">
                            <label class="facillgpdlabel" for="conteudo">Use o campo abaixo para denunciar. Procure responder às perguntas: O que aconteceu? Quem fez isso (Nome, cargo, setor) ? Quando aconteceu? Onde aconteceu? Como aconteceu?</label>
                            <textarea placeholder="Conteúdo da denúncia" class="facillgpdtextarea" type="text" name="conteudo" id="conteudo" rows="4"></textarea>
                        </div>
                    </div>
                    <div class="facillgpdfeedback facillgpd-none" id="facillgpdfeedback-denuncia">
                        <p id="facillgpdtxt-feedback-denuncia"></p>
                    </div>
                    <button class="facillgpd-button mr-1" id="facillgpd-button-denuncia" type="button" :style="{ 'background-color': corButton, 'color': corSubtitulos }">
                        Enviar Denúncia
                    </button>
                    <button class="facillgpd-button facillgpdconsulta" id="facillgpd-consulta" type="button" :style="{ 'background-color': corButtonConsulta, 'color': corSubtitulos }">   
                        Consultar protocolo
                    </button>
                </div>

                <button id="flgpdmodal-exit" class="flgpdmodal-close flgpdmodal-exit">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                    <line x1="9" y1="9" x2="15" y2="15"></line>
                    <line x1="15" y1="9" x2="9" y2="15"></line>
                    </svg>
                </button>
            
    </div>
</template>

<script setup>
export default {
  props: ["corFundo", "corSubtitulos", "corButton", "corButtonConsulta", "textInicial", "textSequencia", "textDenuncia"],
  data() {
    return {
        exibir: "requisicao"
    };
  },
  methods: {
    getHtmlFrom(str) {
      if (str) {
        return str
          .replace(/(?:\*)([^*]*)(?:\*)/gm, "<strong>$1</strong>")
           .replace("{#", "<b>Contato: </b>")
          .replace("#}", "")
          .replace(/(?:_)([^_]*)(?:_)/gm, "<i>$1</i>")
          .replace(/(?:~)([^~]*)(?:~)/gm, "<strike>$1</strike>")
          .replace(/\n/gim, "<br/>")
          .replace(/(?:```)([^```]*)(?:```)/gm, "<tt>$1</tt>");
      } else {
        return str;
      }
    },
  },
  async mounted() {

    if(this.$route.query && this.$route.query.incidente) this.exibir = "denuncia";

    if(this.textInicial.match('#AquiDireitos') || this.textInicial.match('#PoliticaPrivacidade')) {
      window.document.getElementById('textInicial').innerHTML = this.textInicial.replace(/#AquiDireitos/g, `<a href="https://www.gov.br/anpd/pt-br/documentos-e-publicacoes/guia-orientativo-cookies-e-protecao-de-dados-pessoais.pdf" target="_blank" class="facillgpd-politica-link" style="font-weight: bold;text-decoration: underline;" >clique aqui</a>`);
    }

    this.$watch('textInicial', async (textInicial) => {
      if(textInicial.match('#AquiDireitos') || textInicial.match('#PoliticaPrivacidade')) {
        window.document.getElementById('textInicial').innerHTML = textInicial.replace(/#AquiDireitos/g, `<a href="https://www.gov.br/anpd/pt-br/documentos-e-publicacoes/guia-orientativo-cookies-e-protecao-de-dados-pessoais.pdf" target="_blank" class="facillgpd-politica-link" style="font-weight: bold;text-decoration: underline;" >clique aqui</a>`);
      }
    });
  }
};
</script>

<style scoped>
    #requisicao, #denuncia, #consulta {
        margin-bottom: 10px;
    }
    #logo-icon {
        height: 55px;
    }
    .facillgpd-link {
        font-weight: bold;
        font-family: Verdana, sans-serif !important;
        text-decoration: underline;
        color: #FFFFFF;
    }
    .facillgpd-display-ajuste {
        display: block;
        margin-top: 15px;
    }
    @media (max-width: 800px) {
        .facillgpd-display-ajuste {
            display: none;
        }
    }
    .facillgpd-title {
        font-weight: bold;
        font-size: 19px;
        line-height: 1.3;
        font-family: Verdana, sans-serif !important;
        margin: 0px;
        margin-top: 10px;
        margin-bottom: 10px;
        color: #FFFFFF;
    }
    .facillgpd-subtitle {
        font-size: 14px;
        line-height: 1.3;
        font-family: Verdana, sans-serif !important;
        margin-top: 3px;
        color: #FFFFFF;
    }
    .facillgpd-button {
        width: 250px;
        padding: 10px;
        font-size: 13px;
        line-height: 1.3;
        background-color: #FBBF24;
        border-radius: 5px;
        color: #FFFFFF;
        font-family: Verdana, sans-serif !important;
        border: 0;
        margin-top: 2px;
    }
    .facillgpddesabilitado {
        background-color:white !important;
        color: #FBBF24 !important;
    }
    .facillgpdconsulta {
        background-color: #8a8888;
        color: #FFFFFF;
    }
    .facillgpdlabel {
        font-size: 14px;
        line-height: 1.3;
        font-family: Verdana, sans-serif !important;
        color: #FFFFFF;
    }
    .facillgpdinput {
        width: 100%;
        font-size: 13px;
        line-height: 1.3;
        padding-left: 5px;
        font-family: Verdana, sans-serif !important;
        height: 28px;
        border: none;
        border-radius: 5px;
        margin-top: 10px;
        color: #000;
    }
    .facillgpdinput:focus{
        outline: none;
    }
    .facillgpdselect {
        width: 100%;
        padding: 0px;
        background: #fff;
        font-size: 13px;
        line-height: 1.3;
        padding-left: 5px;
        font-family: Verdana, sans-serif !important;
        height: 28px;
        border: none;
        border-radius: 5px;
        margin-top: 10px;
        color: #000;
    }
    .facillgpdselect:focus{
        outline: none;
    }
    .facillgpdtextarea {
        width: 100%;
        font-size: 13px;
        line-height: 1.3;
        padding-left: 5px;
        font-family: Verdana, sans-serif !important;
        border: none;
        border-radius: 5px;
        margin-top: 10px;
        margin-bottom: 5px;
    }
    .facillgpdtextarea:focus{
        outline: none;
    }
    .flgpdmodal {
        position: fixed;
        width: 100vw;
        height: 100vh;
        opacity: 0;
        visibility: hidden;
        line-height: 1.3;
        transition: all 0.3s ease;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .flgpdopen {
            visibility: visible;
            opacity: 1;
            transition-delay: 0s;
            z-index: 99999;
    }
    .flgpdmodal-bg {
            position: absolute;
            background: rgba(0, 0, 0, 0.5);
            width: 100%;
            height: 100%;
    }
    .facillgpdfeedback {
        padding: 5px 5px;
        color: #fff;
        font-size: 13px;
        line-height: 1.3;
        font-family: Verdana, sans-serif !important;
    }
    .facillgpdfeedback-error {
        background-color: rgba(239, 68, 68, 1);
    }
    .facillgpdfeedback-success {
        background-color: rgba(52, 211, 153, 1);
    }
    .flgpdmodal-container {
            border-radius: 5px;
            background: #0097B2;
            position: relative;
            padding: 8px 20px;
            display: flex;
            width: 820px;
            flex-direction: column;
            overflow:auto;
    }
    @media (max-width: 800px) {
        .flgpdmodal-container {
            height: 600px;
            padding: 15px;
            border-radius: 8px;
        }
    }
    .facillgpdflex-item>input, select {
        height: 28px;
        font-size: 10px;
    }
    .flgpdmodal-container>h2 {
        font-size: 20px;
    }
    .facillgpdflex-row {
        display: flex;
        flex-direction: row;
        gap: 30px 30px;
    }
    .facillgpdflex-item {
        flex: 1;
        align-self: stretch;
        display: flex;
        flex-direction: column;
    }
    .flgpdmodal-close {
            position: absolute;
            right: 5px;
            top: 5px;
            outline: none;
            appearance: none;
            color: #FFFFFF;
            background: none;
            border: 0px;
            font-weight: bold;
            cursor: pointer;
    }
    .flgpdmodal-icon {
        height: 25px;
        width: 25px;
        color: inherit;
    }
    .flex-grid {
        display: flex;
        justify-content: space-between;
        gap: 0px;
    }
    @media (min-width: 980px) {
        .flex-wrapper {
            padding-left: 6%;
            padding-right: 6%;
        }
        .flex-grid {margin-top: 10px;}
        .d-col-1 						{width: 7.05%;}
        .d-col-2 						{width: 15.5%;}
        .d-col-3,
        .d-col-thirds 					{width: 23.95%;}
        .d-col-4,
        .d-col-quarters 				{width: 32.4%;}
        .d-col-5 						{width: 40.85%;}
        .d-col-6,
        .d-col-halfs					{width: 49.3%;}
        .d-col-7 						{width: 57.75%;}
        .d-col-8 						{width: 66.2%;}
        .d-col-9 						{width: 74.65%;}
        .d-col-10 						{width: 83.1%;}
        .d-col-11 						{width: 91.55%;}
        .d-col-12,
        .d-col-full 					{width: 100%;}
    }
    @media only screen and (min-width: 661px) and (max-width: 979px) {
        .flex-wrapper {
            padding-left: 5%;
            padding-right: 5%;
        }
        .flex-grid {flex-flow: row wrap;}
        .t-col-4,
        .t-col-6,
        .t-col-12 {margin-top: 5px;}
        .t-col-4 		{width: 31.46%	}
        .t-col-6 		{width: 48.6%;}
        .t-col-12 		{width: 100%;}
    }
    @media only screen and (min-width: 0px) and (max-width: 660px) {
        .flex-wrapper {
            padding-left: 3%;
            padding-right: 3%;
        }
        .flex-grid {flex-flow: row wrap;}
        .m-col-6,
        .m-col-12 {margin-top: 5px;}
        .m-col-6 		{width: 47.2%;}
        .m-col-12 		{width: 100%;}
    }
</style>
